import React, { useEffect, useState } from 'react';
import api from '../../utils/api';
import { City, Province } from '../../model/TeacherInfo';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import { TypeComponent } from '../../pages/teacher/Administrative';

interface Props {
  redirect: (page: TypeComponent) => void;
}
interface Invoice {
  kind: string;
  first_name: string;
  email: string;
  destination_id: string;
  last_name: string;
  company_name: string;
  vat_number: string;
  street_name: string;
  street_number: string;
  zip: string;
  city: City;
  province: Province;
  cf: string;
}
interface Form {
  // client_id: string;
  client_cf: string;
  client_destination_id: string;
  client_street_name: string;
  client_street_number: string;
  client_zip: string;
  client_city_id: number;
  client_province_id: number;
  subtotal: number;
  description: string;
  client_email_address: string;
  invoice_number: string;
  date: string;
}
interface CompanyForm extends Form {
  client_denomination: string;
  client_vat: string;
}
interface PrivateForm extends Form {
  client_first_name: string;
  client_last_name: string;
  client_vat: string;
}
interface InvoiceData {
  invoice_number: number;
  invoice_date: string;
  description: string;
  subtotal: number;
}

type Receiver = 'company' | 'person';

const InvoiceCreator: React.FC<Props> = ({ redirect }) => {
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [invoicesKind, setInvoicesKind] = useState<Invoice[]>([]);
  const [receiver, setReceiver] = useState<Receiver>();
  const [cities, setCities] = useState<City[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    vat: '',
    email: '',
    cf: '',
    destination_id: '',
  });
  const [hasStartedTypingVAT, setHasStartedTypingVAT] = useState<boolean>(false);
  const [hasStartedTypingCF, setHasStartedTypingCF] = useState<boolean>(false);
  const [hasStartedTypingEmail, setHasStartedTypingEmail] = useState<boolean>(false);
  const [hasStartedTypingDestinationId, setHasStartedTypingDestinationId] = useState<boolean>(false);

  const validateVAT = (vat: string) => {
    if (!/^\d{11}$/.test(vat)) {
      return 'Partita IVA deve essere composta da 11 cifre numeriche oppure deve essere vuota.';
    }
    return '';
  };
  const validateEmail = (email: string) => {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      return 'Email non valida.';
    }
    return '';
  };
  const validateCF = (cf: string) => {
    if (!/^[a-zA-Z]{6}\d{2}[a-ehlmpr-tA-EHLMPR-T]{1}\d{2}[a-zA-Z]\d{3}[a-zA-Z]$/.test(cf)) {
      return 'Codice fiscale non valido.';
    }
    return '';
  };
  const validateDestinationId = (destination_id: string) => {
    if (!/^[a-zA-Z0-9]{7}$/.test(destination_id)) {
      return 'Deve essere composto da 7 caratteri o 7 zeri.';
    }
    return '';
  };
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice>({
    kind: '',
    first_name: '',
    email: '',
    destination_id: '',
    last_name: '',
    company_name: '',
    vat_number: '',
    street_name: '',
    street_number: '',
    zip: '',
    city: { id: 0, name: '' },
    province: { id: 0, name: '', initials: '' },
    cf: '',
  });

  const [form, setForm] = useState<CompanyForm | PrivateForm>({
    // client_id: '',
    client_vat: '',
    client_cf: '',
    client_destination_id: '',
    // client_first_name: '',
    // client_last_name: '',
    client_denomination: '',
    client_street_name: '',
    client_street_number: '',
    client_zip: '',
    client_city_id: 0,
    client_province_id: 0,
    subtotal: null,
    description: '',
    client_email_address: '',
    invoice_number: '',
    date: '',
  });
  const { setLoading } = useLoading();
  const { setError } = useError();

  useEffect(() => {
    console.log('selectedInvoice:', selectedInvoice);
    if (selectedInvoice.kind === 'company') {
      setReceiver('company');

      setForm({
        // client_id: '',
        client_vat: selectedInvoice.vat_number,
        client_cf: selectedInvoice.cf,
        client_destination_id: selectedInvoice.destination_id,
        client_denomination: selectedInvoice.company_name,
        client_street_name: selectedInvoice.street_name,
        client_street_number: selectedInvoice.street_number,
        client_zip: selectedInvoice.zip,
        client_city_id: selectedInvoice.city.id,
        client_province_id: selectedInvoice.province.id,
        subtotal: null,
        description: '',
        client_email_address: selectedInvoice.email,
        invoice_number: '',
        date: '',
      });
    } else if (selectedInvoice.kind === 'person') {
      setReceiver('person');
      setForm({
        // client_id: '',
        client_cf: selectedInvoice.cf,
        client_vat: selectedInvoice.vat_number,
        client_destination_id: selectedInvoice.destination_id,
        client_first_name: selectedInvoice.first_name,
        client_last_name: selectedInvoice.last_name,
        client_street_name: selectedInvoice.street_name,
        client_street_number: selectedInvoice.street_number,
        client_zip: selectedInvoice.zip,
        client_city_id: selectedInvoice.city.id,
        client_province_id: selectedInvoice.province.id,
        subtotal: null,
        description: '',
        client_email_address: selectedInvoice.email,
        invoice_number: '',
        date: '',
      });
    }
  }, [selectedInvoice]);

  useEffect(() => {
    console.log('form:', form);
    checkFormValidity();
  }, [form]);

  const checkFormValidity = () => {
    let isFormValid = false;
    let errorsTemp: { [key: string]: string } = {};

    if (receiver === 'company') {
      const companyForm = form as CompanyForm;
      let vatError = '';
      let emailError = '';
      let cfError = '';
      let destinationIdError = '';

      if (hasStartedTypingVAT) {
        vatError = validateVAT(companyForm.client_vat);
        if (companyForm.client_vat === '') {
          vatError = '';
        } else if (vatError !== '') {
          errorsTemp.vat = vatError;
        }
      }
      if (hasStartedTypingEmail) {
        emailError = validateEmail(companyForm.client_email_address);
        if (emailError) {
          errorsTemp.email = emailError;
        }
      }
      if (hasStartedTypingCF) {
        cfError = validateCF(companyForm.client_cf);
        if (cfError) {
          errorsTemp.cf = cfError;
        }
      }
      if (hasStartedTypingDestinationId) {
        destinationIdError = validateDestinationId(companyForm.client_destination_id);
        if (companyForm.client_destination_id === '') {
          destinationIdError = '';
        } else if (destinationIdError) {
          errorsTemp.destination_id = destinationIdError;
        }
      }

      isFormValid =
        !vatError &&
        !emailError &&
        !cfError &&
        !destinationIdError &&
        companyForm.client_denomination !== '' &&
        companyForm.client_cf !== '' &&
        companyForm.client_street_name !== '' &&
        companyForm.client_street_number !== '' &&
        companyForm.client_zip !== '' &&
        companyForm.client_city_id !== 0 &&
        companyForm.client_province_id !== 0 &&
        companyForm.subtotal >= 1 &&
        companyForm.description !== '' &&
        companyForm.client_email_address !== '' &&
        companyForm.invoice_number !== '' &&
        companyForm.date !== '';
    } else if (receiver === 'person') {
      const privateForm = form as PrivateForm;
      let vatError = '';
      let emailError = '';
      let cfError = '';
      let destinationIdError = '';

      if (hasStartedTypingVAT) {
        vatError = validateVAT(privateForm.client_vat);
        if (privateForm.client_vat === '') {
          vatError = '';
        } else if (vatError !== '') {
          errorsTemp.vat = vatError;
        }
      }
      if (hasStartedTypingEmail) {
        emailError = validateEmail(privateForm.client_email_address);
        if (emailError) {
          errorsTemp.email = emailError;
        }
      }
      if (hasStartedTypingCF) {
        cfError = validateCF(privateForm.client_cf);
        if (cfError) {
          errorsTemp.cf = cfError;
        }
      }
      if (hasStartedTypingDestinationId) {
        destinationIdError = validateDestinationId(privateForm.client_destination_id);
        if (privateForm.client_destination_id === '') {
          destinationIdError = '';
        } else if (destinationIdError) {
          errorsTemp.destination_id = destinationIdError;
        }
      }
      isFormValid =
        !vatError &&
        !emailError &&
        !cfError &&
        !destinationIdError &&
        privateForm.client_first_name !== '' &&
        privateForm.client_last_name !== '' &&
        privateForm.client_cf !== '' &&
        privateForm.client_street_name !== '' &&
        privateForm.client_street_number !== '' &&
        privateForm.client_zip !== '' &&
        privateForm.client_city_id !== 0 &&
        privateForm.client_province_id !== 0 &&
        privateForm.subtotal !== null &&
        privateForm.subtotal >= 1 &&
        privateForm.description !== '' &&
        privateForm.client_email_address !== '' &&
        privateForm.invoice_number !== '' &&
        privateForm.date !== '';
    }

    setErrors(errorsTemp);
    setIsDisabled(!isFormValid);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, client_email_address: e.target.value });
    setHasStartedTypingEmail(true);
  };

  const handleCFChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, client_cf: e.target.value });
    setHasStartedTypingCF(true);
  };

  const handleDestinationIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, client_destination_id: e.target.value });
    setHasStartedTypingDestinationId(true);
  };

  const handleCityChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setForm({ ...form, client_city_id: Number(event.target.value) });
  };

  const handleProvinceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setForm({ ...form, client_province_id: Number(event.target.value) });
  };

  const handleReceiverChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setReceiver(event.target.value as Receiver);
    setSelectedInvoice({
      kind: '',
      first_name: '',
      email: '',
      destination_id: '',
      last_name: '',
      company_name: '',
      vat_number: '',
      street_name: '',
      street_number: '',
      zip: '',
      city: { id: 0, name: '' },
      province: { id: 0, name: '', initials: '' },
      cf: '',
    });
    setForm({
      // client_id: '',
      client_vat: '',
      client_cf: '',
      client_destination_id: '',
      client_first_name: '',
      client_last_name: '',
      client_denomination: '',
      client_street_name: '',
      client_street_number: '',
      client_zip: '',
      client_city_id: 0,
      client_province_id: 0,
      subtotal: null,
      description: '',
      client_email_address: '',
      invoice_number: '',
      date: '',
    });
  };

  useEffect(() => {
    setLoading(true);
    api
      .get('/province?page_size=200')
      .then((response) => {
        console.log('Province caricate:', response.data.provinces);
        setProvinces(response.data.provinces);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    console.log('selectedProvinceId:', form.client_province_id);
    if (!form.client_province_id) return;
    setLoading(true);
    api
      .get(`/city?province_id=${form.client_province_id}&page_size=200`)
      .then((response) => {
        console.log('Città caricate:', response.data.cities);
        setCities(response.data.cities);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [form.client_province_id]);

  useEffect(() => {
    setLoading(true);
    api
      .get('/invoice/historical')
      .then((response) => {
        setInvoices(response.data.clients);
        setReceiver('company');
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    console.log('receiver:', receiver);
    let invoiceKindSelected = invoices.filter((i) => {
      return i.kind === receiver;
    });
    setInvoicesKind(invoiceKindSelected);
  }, [receiver]);

  const submit = () => {
    setLoading(true);
    api
      .post('/invoice', form)
      .then((response) => {
        console.log('Fattura creata:', response.data);
        redirect('ActiveInvoices');
      })
      .catch((error) => {
        console.error('Errore durante la creazione della fattura:', error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex flex-col gap-y-4 overflow-auto">
      <div className="flex w-full gap-2">
        <select
          className="w-3/5 sm:w-1/2 border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
          disabled={!invoices}
          value={
            receiver === 'company'
              ? selectedInvoice.company_name
              : selectedInvoice.cf
          }
          onChange={(e) =>
            setSelectedInvoice(
              invoices.find((inv) => {
                if(receiver === 'company'){
                   return inv.company_name === e.target.value}
                  else{ return inv.cf === e.target.value};
              }) || selectedInvoice
            )
          }>
          <option value="" disabled>
            Seleziona una fattura
          </option>
          {invoicesKind.map((invoice, id) => (
            <option
              key={id}
              value={receiver === 'company' ? invoice.company_name : invoice.cf}>
              {invoice.company_name} {invoice.first_name + ' ' + invoice.last_name}
            </option>
          ))}
        </select>
        <select
          className="w-2/5 sm:w-1/2 border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
          value={receiver}
          onChange={handleReceiverChange}>
          <option value={'company'}>Azienda</option>
          <option value={'person'}>Privato</option>
        </select>
      </div>
      <div className="flex flex-col lg:flex-row gap-4 pb-4">
        <div className="flex flex-col w-full gap-y-4">
          {receiver === 'company' && (
            <>
              <div className="text-xl font-bold">Dati azienda</div>
              <div className="flex gap-2">
                <div className="flex flex-col w-full">
                  <input
                    className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
                    name="client_denomination"
                    value={(form as CompanyForm).client_denomination}
                    onChange={(e) => setForm({ ...form, client_denomination: e.target.value })}
                    type="text"
                    placeholder="Nome azienda"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <input
                    className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
                    name="vat_number"
                    value={(form as CompanyForm).client_vat}
                    onChange={(e) => {
                      setForm({ ...form, client_vat: e.target.value });
                      setHasStartedTypingVAT(true);
                    }}
                    type="text"
                    placeholder="P.IVA"
                    maxLength={11}
                  />

                  {hasStartedTypingVAT && errors.vat && <span className="text-red-600">{errors.vat}</span>}
                </div>
              </div>
            </>
          )}
          {receiver === 'person' && (
            <>
              <div className="text-xl font-bold">Dati privato</div>
              <div className="flex flex-col gap-y-4">
                <div className="flex gap-2">
                  <input
                    className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
                    name="client_first_name"
                    value={(form as PrivateForm).client_first_name}
                    onChange={(e) => setForm({ ...form, client_first_name: e.target.value })}
                    type="text"
                    placeholder="Nome"
                  />
                  <input
                    className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
                    name="client_last_name"
                    value={(form as PrivateForm).client_last_name}
                    onChange={(e) => setForm({ ...form, client_last_name: e.target.value })}
                    type="text"
                    placeholder="Cognome"
                  />
                </div>
                <div className="flex flex-col w-full">
                  <input
                    className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
                    name="vat_number"
                    value={(form as PrivateForm).client_vat}
                    onChange={(e) => { 
                      setForm({ ...form, client_vat: e.target.value });
                      setHasStartedTypingVAT(true);
                    }}
                    type="text"
                    placeholder="P.IVA *"
                    maxLength={11}
                  />
                  {hasStartedTypingVAT && errors.vat && <span className="text-red-600">{errors.vat}</span>}
                </div>
              </div>
            </>
          )}
          <div className="flex gap-2">
            <div className="flex flex-col w-full">
              <input
                className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
                name="cf"
                value={form.client_cf}
                onChange={handleCFChange}
                type="text"
                placeholder="Codice fiscale"
                maxLength={16}
              />
              {hasStartedTypingCF && errors.cf && <span className="text-red-600">{errors.cf}</span>}
            </div>
            <div className="flex flex-col w-full">
              <input
                className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
                name="email"
                value={form.client_email_address}
                onChange={handleEmailChange}
                type="text"
                placeholder="Email"
              />
              {hasStartedTypingEmail && errors.email && <span className="text-red-600">{errors.email}</span>}
            </div>
          </div>
          <div className="flex gap-2">
            <div className="w-1/2 flex flex-col">
              <select
                value={form.client_province_id}
                onChange={handleProvinceChange}
                className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2">
                <option value={0} disabled>
                  Seleziona una provincia
                </option>
                {provinces.map((province) => (
                  <option key={province.id} value={province.id}>
                    {province.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="w-1/2 flex flex-col">
              <select
                disabled={cities.length === 0}
                value={form.client_city_id}
                onChange={handleCityChange}
                className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2">
                <option value={0} disabled>
                  Seleziona una città
                </option>
                {cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="flex gap-2">
            <div className="flex flex-col w-full">
              <input
                className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
                name="zip_code"
                value={form.client_zip}
                onChange={(e) => setForm({ ...form, client_zip: e.target.value })}
                type="text"
                placeholder="CAP"
              />
            </div>
            {receiver === 'company' && (
              <div className="flex flex-col w-full">
                <input
                  className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
                  name="destination_id"
                  value={form.client_destination_id}
                  onChange={handleDestinationIdChange}
                  type="text"
                  placeholder="Codice destinatario"
                  maxLength={7}
                />
                {hasStartedTypingDestinationId && errors.destination_id && (
                  <span className="text-red-600">{errors.destination_id}</span>
                )}
              </div>
            )}
            {receiver === 'person' && (
              <div className="flex flex-col w-full">
                <input
                  className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
                  name="destination_id"
                  value={form.client_destination_id}
                  onChange={handleDestinationIdChange}
                  type="text"
                  placeholder="Codice destinatario *"
                  maxLength={7}
                />
                {hasStartedTypingDestinationId && errors.destination_id && (
                  <span className="text-red-600">{errors.destination_id}</span>
                )}
              </div>
            )}
          </div>
          <div className="flex gap-2">
            <input
              className="w-3/4 border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
              name="street"
              value={form.client_street_name}
              onChange={(e) => setForm({ ...form, client_street_name: e.target.value })}
              type="text"
              placeholder="Via"
            />
            <input
              className="w-1/4 border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
              name="street_number"
              value={form.client_street_number}
              onChange={(e) => setForm({ ...form, client_street_number: e.target.value })}
              type="text"
              placeholder="Numero"
            />
          </div>
        </div>
        <div className="flex flex-col w-full gap-y-4">
          <div className="text-xl font-bold">Dati fattura</div>
          <div className="flex gap-2">
            <input
              className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
              name="invoice_number"
              value={form.invoice_number}
              onChange={(e) => setForm({ ...form, invoice_number: e.target.value })}
              type="text"
              placeholder="Numero fattura"
            />
            <input
              className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
              name="date"
              value={form.date}
              onChange={(e) => setForm({ ...form, date: e.target.value })}
              type="date"
              placeholder="Data fattura"
            />
          </div>

          <input
            className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
            name="description"
            value={form.description}
            onChange={(e) => setForm({ ...form, description: e.target.value })}
            type="text"
            placeholder="Descrizione"
          />
          <input
            className="w-full border-2 border-black border-opacity-50 rounded-lg px-2 text-lg py-2"
            name="subtotal"
            value={form.subtotal !== 0 ? form.subtotal : null}
            onChange={(e) => setForm({ ...form, subtotal: Number(e.target.value) })}
            type="number"
            placeholder="Totale Fattura"
          />

          <div className="flex flex-col items-center justify-center gap-y-4">
            <button
              className="bg-fpcred rounded-lg text-center text-white px-4 py-2 w-1/2 disabled:opacity-50 disabled:cursor-not-allowed"
              onClick={submit}
              disabled={isDisabled}>
              Invia
            </button>
            <p className="opacity-50">* = Facoltativo</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceCreator;
