import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg, EventClickArg, EventInput } from '@fullcalendar/core';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
import api from '../utils/api';
import { BookLessonData } from '../model/BookLessonData';
import useScreenWidth from '../hooks/useScreenWidth';
import { useLoading } from '../context/LoadingContext';
import { useError } from '../context/ErrorContext';

interface CalendarProps {
  bookLessonData: BookLessonData;
  onDateSelect: (date: DateSelectArg) => void;
}

const MyCalendar: React.FC<CalendarProps> = ({ bookLessonData, onDateSelect }) => {
  const [availabilities, setAvailabilities] = useState<any[]>([]);
  const [events, setEvents] = useState<EventInput[]>([]);
  const [render, setRender] = useState(false);
  const [initialView, setInitialView] = useState<string>('timeGridDay');
  const screenWidth = useScreenWidth();
  const { setLoading } = useLoading();
  const { setError } = useError();

  const today = new Date(Date.now());
  const start = new Date(new Date().setDate(today.getDate() - 30));
  const maxDate = new Date(new Date().setDate(today.getDate() + 30));

  useEffect(() => {
    console.log('booklessondata is: ', bookLessonData);
    setLoading(true);
    api
      .post('/availability/calendar', {
        student_id: bookLessonData.child.id,
        teacher_id: bookLessonData.teacher ? bookLessonData.teacher.id : undefined,
        subject_id: bookLessonData.subject.id,
        searched_lesson_length: bookLessonData.duration,
        location: bookLessonData.location,
      })
      .then((response) => {
        const availabilities = response.data.availabilities;
        setAvailabilities(availabilities);
        const availableEvents = calculateAvailableTimes(availabilities);
        const unavailableEvents = calculateUnavailableTimes(availabilities);
        const events = availableEvents.concat(unavailableEvents);
        setEvents(events);
        setRender(true);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [bookLessonData]);

  useEffect(() => {
    if (screenWidth < 1024) {
      setInitialView('timeGridDay');
    } else {
      setInitialView('timeGridWeek');
    }
  }, [screenWidth]);

  const calculateAvailableTimes = (availabilities) => {
    const availableEvents: EventInput[] = [];

    availabilities.forEach((availability) => {
      const availableStart = new Date(availability.start_date_time);
      const availableEnd = new Date(availability.end_date_time);

      let currentTime = availableStart;

      while (currentTime < availableEnd) {
        const halfHourLater = new Date(currentTime.getTime() + 30 * 60000); // Aggiungi 30 minuti

        if (halfHourLater <= availableEnd) {
          availableEvents.push({
            start: currentTime,
            end: halfHourLater,
            display: 'background',
            classNames: ['available-background'],
            editable: false,
          });
        }

        currentTime = halfHourLater;
      }
    });

    return availableEvents;
  };

  const calculateUnavailableTimes = (availabilities) => {
    const unavailableEvents: EventInput[] = [];
    const startOfDay = today;
    const endOfDay = maxDate;
    let currentTime = startOfDay;

    availabilities.forEach((availability) => {
      const availableStart = new Date(availability.start_date_time);
      const availableEnd = new Date(availability.end_date_time);

      if (currentTime < availableStart) {
        unavailableEvents.push({
          start: currentTime,
          end: availableStart,
          display: 'background',
          classNames: ['stripe-background', 'cursor-not-allowed hover:cursor-not-allowed', 'select-none'],
          editable: false,
        });
      }
      currentTime = availableEnd;
    });

    if (currentTime < endOfDay) {
      unavailableEvents.push({
        start: currentTime,
        end: endOfDay,
        display: 'background',
        classNames: ['stripe-background', 'cursor-not-allowed', 'select-none'],
        editable: false,
      });
    }

    return unavailableEvents;
  };

  const handleDateClick = (arg: any) => {
    if (arg.view.type === 'dayGridMonth' || arg.view.type === 'dayGridYear') {
      let calendarApi = arg.view.calendar;
      calendarApi.changeView('timeGridDay', arg.date);
    }
  };

  const selectAllow = (selectInfo: DateSelectArg) => {
    if (
      availabilities.some(
        (availability) =>
          selectInfo.start >= new Date(availability.start_date_time) &&
          selectInfo.end <= new Date(availability.end_date_time) &&
          selectInfo.end.getTime() - selectInfo.start.getTime() <= 30 * 60 * 1000
      )
    ) {
      return true;
    }
    return false;
  };

  const selectDate = (selectInfo: DateSelectArg) => {
    onDateSelect(selectInfo); // Call the callback to change the step
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div>
        {/* Insert custom HTML here */}
        <div dangerouslySetInnerHTML={{ __html: eventInfo.event.extendedProps.customHtml }} />
      </div>
    );
  };

  return (
    <>
      {render && events.length > 0 && (
        <FullCalendar
          key={initialView}
          selectAllow={selectAllow}
          locale={itLocale}
          plugins={[timeGridPlugin, interactionPlugin]}
          initialView={initialView}
          headerToolbar={{
            start: 'prev',
            center: 'title',
            end: 'next',
          }}
          editable={false}
          eventDragMinDistance={99}
          selectable={true}
          selectMirror={true}
          weekends={true}
          validRange={{ start: start, end: maxDate }}
          events={events}
          select={(selectInfo: DateSelectArg) => selectDate(selectInfo)}
          dateClick={handleDateClick}
          allDaySlot={false}
          height={'100%'}
          eventContent={renderEventContent}
          slotMinTime={'07:00:00'}
          slotMaxTime={'22:00:00'}
          selectLongPressDelay={70}

        />
      )}
    </>
  );
};

export default MyCalendar;
