import { useTranslation } from "react-i18next";
import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoFPCH } from '../ui/logoFPCH.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from "react";
import NavbarHamburger from "../ui/NavbarHamburger";
import UserCircle from "../ui/UserCircle";
import useAuth from "../hooks/useAuth";
interface NavbarProps {
    descriptionVisible: boolean;
}

function Navbar({ descriptionVisible }: NavbarProps) {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState(false);
    const { auth } = useAuth();
    const [isOpenLoggedMenu, setIsOpenLoggedMenu] = useState(false);

    // Redirect to login page when clicking on login button
    const handleClickLogin = () => {
        if (!auth.isAuth) {
            navigate("/login");
            setIsOpen(false);
        } else {
            setIsOpenLoggedMenu(!isOpenLoggedMenu);
            setIsOpen(false);
        }
    }

    useEffect(() => {
        if (!auth.isAuth) {
            setIsOpenLoggedMenu(false);
        }
    }, [auth]);

    // Toggle the navbar menu on mobile
    const toggleMenu = () => {
        if (isOpenLoggedMenu || isOpen) {
            setIsOpenLoggedMenu(false);
            setIsOpen(false);
        } else {
            setIsOpen(true);
        }
    };

    return (
        <>
            {/*The header component sets the width of the navbar and draw he top red line.*/}
            <header className={`w-full h-fit flex flex-col`}>
                <div className={`bg-fpcpink border-t-4  border-fpcred w-full h-fit justify-center flex`}>
                    <nav className="max-w-screen-xl w-full flex flex-col items-center lg:flex-row">
                        {/* This div is for align in a single line the logo and the button on mobile */}
                        <div className="bg-fpcpink flex w-full h-fit justify-between align-left items-center lg:w-fit px-5 py-2 lg:pl-10 lg:pr-0 lg:py-2">
                            <a href="/" className={`${isOpen ? 'opacity-0' : 'opacity-100'} lg:opacity-100`}>
                                <LogoFPCH className="h-7 lg:h-8 w-auto" />
                            </a>
                            <div className="flex flex-row w-fit h-fit space-x-5 items-center">
                                <button
                                    className={`font-sans text-base transition duration-150 text-fpcred lg:hidden 
                                                ${auth.isAuth ? '' : 'bg-fpcpink border-fpcred border-2 rounded-full px-3 py-0.5'}`}
                                    onClick={handleClickLogin}
                                >
                                    {auth.isAuth ? <UserCircle isOpen={isOpenLoggedMenu} mouseHover={''} openPar={'stroke-fpcred'} /> : t("login.title")}
                                </button>
                                <button className="flex lg:hidden" onClick={toggleMenu}>
                                    <NavbarHamburger isOpen={isOpen || isOpenLoggedMenu} />
                                </button>
                            </div>
                        </div>
                        {/* This ul is for the navbar pages navigation menu */}
                        {descriptionVisible && <ul className={`bg-fpcpink w-full m-auto space-y-6 lg:space-y-0 lg:space-x-5 items-center flex-col flex lg:justify-end overflow-hidden transition-all lg:transition-none duration-[600ms] ${isOpen ? 'translate-y-0 h-screen' : '-translate-y-2 h-0'} lg:flex-row lg:flex lg:translate-y-0 lg:h-fit lg:pr-10 lg:pl-0 pt-1`}>
                            <li className="w-fit">
                                <NavLink to="/" onClick={toggleMenu} className={`font-sans text-base transition duration-150 ${location.pathname === '/' ? "text-fpcred" : "text-grey1 hover:text-black"}`}>
                                    {t("homeTitle")}
                                </NavLink>
                            </li>
                            <li className="w-fit">
                                <NavLink to="/about" onClick={toggleMenu} className={`font-sans text-base transition duration-150 ${location.pathname === '/about' ? "text-fpcred" : "text-grey1 hover:text-black"}`}>
                                    {t("aboutTitle")}
                                </NavLink>
                            </li>
                            <li className="w-fit">
                                <NavLink to="/pricing" onClick={toggleMenu} className={`font-sans text-base transition duration-150 ${location.pathname === '/pricing' ? "text-fpcred" : "text-grey1 hover:text-black"}`}>
                                    {t("pricingTitle")}
                                </NavLink>
                            </li>
                            <li className="w-fit">
                                <NavLink to="/workwithus" onClick={toggleMenu} className={`font-sans text-base transition duration-150 ${location.pathname === '/workwithus' ? "text-fpcred" : "text-grey1 hover:text-black"}`}>
                                    {t("workWithUsTitle")}
                                </NavLink>
                            </li>
                            <li className="hidden lg:flex lg:pl-2">
                                <button className={`font-sans text-base transition duration-150 text-white py-1 ${auth.isAuth ? 'bg-inherit w-fit h-fit px-1' : 'bg-fpcred rounded-full hover:bg-buttonhover active:bg-buttonactive px-6'}`} onClick={handleClickLogin}>
                                    {auth.isAuth ? <UserCircle isOpen={isOpenLoggedMenu} mouseHover={'stroke-black'} openPar={'stroke-fpcred'} /> : t("login.title")}
                                </button>
                            </li>
                        </ul>}
                    </nav>
                </div>
            </header>
        </>
    );
}
export default Navbar;
