import React, { useEffect, useState } from 'react';
import { ReactComponent as Creafattura } from '../../ui/CreaFattura.svg';
import { ReactComponent as Pagamenti } from '../../ui/Pagamenti.svg';
import { ReactComponent as FattureRicevute } from '../../ui/FattureRicevute.svg';
import { ReactComponent as FattureInviate } from '../../ui/FattureInviate.svg';
import InvoiceCreator from '../../components/teacher/InvoiceCreator';
import Invoices from '../../components/teacher/Invoices';
import withAuth from '../../utils/IsAuth';

export type TypeComponent = 'InvoiceCreator' | 'Payments' | 'ReceivedInvoices' | 'ActiveInvoices';

const TeacherAdministrative: React.FC = () => {
  const [activeComponent, setActiveComponent] = useState<TypeComponent>('InvoiceCreator');

  const renderComponent = (component: TypeComponent) => {
    switch (component) {
      case 'InvoiceCreator':
        return <InvoiceCreator redirect={setActiveComponent} />;
      case 'Payments':
        return (
          <div>
            {/* Content for Pagamenti */}
            <h2>Pagamenti</h2>
          </div>
        );
      case 'ReceivedInvoices':
        return (
          <div>
            {/* Content for Fatture Ricevute */}
            <Invoices type="passive" />
          </div>
        );
      case 'ActiveInvoices':
        return <Invoices type="active" />;
      default:
        return null;
    }
  };

  return (
    <div className="h-full w-full flex flex-col items-center gap-y-4">
      <div className="p-4 flex w-full justify-between bg-fpcred rounded-lg">
        <div>
          <h1 className="text-xl font-bold text-white">Notifiche</h1>
        </div>
        <div>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17.0001M15 17L9 17.0001M15 17H19C19.5523 17 20 16.5523 20 16V15.4141C20 15.1489 19.8945 14.8946 19.707 14.707L19.1963 14.1963C19.0706 14.0706 19 13.9 19 13.7222V10C19 9.82357 18.9936 9.64855 18.9805 9.4761M9 17.0001L5 17.0001C4.44772 17.0001 4 16.5521 4 15.9998V15.4141C4 15.1489 4.10544 14.8949 4.29297 14.7073L4.80371 14.1958C4.92939 14.0701 5 13.9002 5 13.7224V9.99998C5 6.13401 8.134 3 12 3C12.7116 3 13.3984 3.10618 14.0454 3.30357M18.9805 9.4761C20.1868 8.7873 21 7.48861 21 6C21 3.79086 19.2091 2 17 2C15.8298 2 14.7769 2.50253 14.0454 3.30357M18.9805 9.4761C18.3966 9.80949 17.7205 10 17 10C14.7909 10 13 8.20914 13 6C13 4.9611 13.3961 4.0147 14.0454 3.30357M18.9805 9.4761C18.9805 9.47609 18.9805 9.4761 18.9805 9.4761ZM14.0454 3.30357C14.0459 3.30371 14.0464 3.30385 14.0468 3.304"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="flex flex-col rounded-xl bg-white px-4 gap-4 max-w-4xl w-full overflow-auto">
        <div className="flex sm:justify-around p-2 gap-4">
          <div className="w-1/4 h-full md:w-1/12 cursor-pointer" onClick={() => setActiveComponent('InvoiceCreator')}>
            <Creafattura  className={activeComponent === 'InvoiceCreator' ? 'text-fpcred' : 'text-gray-500'} />
          </div>
          <div className="w-1/4 h-full md:w-1/12 cursor-pointer" onClick={() => setActiveComponent('Payments')}>
            <Pagamenti className={activeComponent === 'Payments' ? 'text-fpcred' : 'text-gray-500'} />
          </div>
          <div className="w-1/4 h-full md:w-1/12 cursor-pointer" onClick={() => setActiveComponent('ReceivedInvoices')}>
            <FattureRicevute className={activeComponent === 'ReceivedInvoices' ? 'text-fpcred' : 'text-gray-500'} />
          </div>
          <div className="w-1/4 h-full md:w-1/12 cursor-pointer" onClick={() => setActiveComponent('ActiveInvoices')}>
            <FattureInviate className={activeComponent === 'ActiveInvoices' ? 'text-fpcred' : 'text-gray-500'} />
          </div>
        </div>
        {renderComponent(activeComponent)}
      </div>
    </div>
  );
};

export default withAuth(TeacherAdministrative);
