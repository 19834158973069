import React, { useEffect, useState } from 'react';
import ProfileGrid from '../../components/profile/ProfileGrid';
import EditProfile from '../../components/profile/EditProfile';
import ChangePassword from '../../components/profile/ChangePassword';
import DeleteAccount from '../../components/profile/DeleteAccount';
import AddFamilyMember, { FamilyMember } from '../../components/AddFamilyMember';
import withAuth from '../../utils/IsAuth';
import api from '../../utils/api';
import { FamilyInfo } from '../../model/FamilyInfo';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import useAuth from '../../hooks/useAuth';

export type ProfileComponent = 'ProfileGrid' | 'EditProfile' | 'ChangePassword' | 'DeleteAccount' | 'AddFamilyMember';

const Profile: React.FC = () => {
  const [activeComponent, setActiveComponent] = useState<ProfileComponent>('ProfileGrid');
  const [familyInfo, setFamilyInfo] = useState<FamilyInfo | null>(null);
  const [familyMember, setFamilyMember] = React.useState<FamilyMember[]>([]);
  const { setLoading } = useLoading();
  const { setError } = useError();
  const { auth, logout } = useAuth();

  useEffect(() => {
    setLoading(true);
    api
      .get('/family')
      .then((response) => {
        setFamilyInfo(response.data.user);
        setFamilyMember(response.data.sons);
      })
      .catch((error) => {
        console.error('Error fetching family info:', error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  const handleShowComponent = (component: ProfileComponent) => {
    setActiveComponent(component);
  };

  if (!familyInfo) return null;

  return (
    <div className="flex flex-col gap-y-4 h-full">
      <div className="bg-fpcred px-4 py-2 rounded-lg flex justify-between">
        <h1 className="text-xl font-bold text-white">Il tuo profilo</h1>
        <div>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15 17V18C15 19.6569 13.6569 21 12 21C10.3431 21 9 19.6569 9 18V17.0001M15 17L9 17.0001M15 17H19C19.5523 17 20 16.5523 20 16V15.4141C20 15.1489 19.8945 14.8946 19.707 14.707L19.1963 14.1963C19.0706 14.0706 19 13.9 19 13.7222V10C19 9.82357 18.9936 9.64855 18.9805 9.4761M9 17.0001L5 17.0001C4.44772 17.0001 4 16.5521 4 15.9998V15.4141C4 15.1489 4.10544 14.8949 4.29297 14.7073L4.80371 14.1958C4.92939 14.0701 5 13.9002 5 13.7224V9.99998C5 6.13401 8.134 3 12 3C12.7116 3 13.3984 3.10618 14.0454 3.30357M18.9805 9.4761C20.1868 8.7873 21 7.48861 21 6C21 3.79086 19.2091 2 17 2C15.8298 2 14.7769 2.50253 14.0454 3.30357M18.9805 9.4761C18.3966 9.80949 17.7205 10 17 10C14.7909 10 13 8.20914 13 6C13 4.9611 13.3961 4.0147 14.0454 3.30357M18.9805 9.4761C18.9805 9.47609 18.9805 9.4761 18.9805 9.4761ZM14.0454 3.30357C14.0459 3.30371 14.0464 3.30385 14.0468 3.304"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 justify-around">
        <div className="bg-white rounded-lg p-4 flex sm:w-2/3">
          <div className="flex flex-col px-4 w-1/2">
            <div className="flex p-4 border-2 text-center">Qui ci sarà l'immagine</div>
            <div className=" text-fpcred font-bold text-center">Famiglia di:</div>
            <div className="flex p-4 justify-center">
              {familyInfo.first_name} {familyInfo.last_name}
            </div>
          </div>
          <div className="flex flex-col px-4 w-1/2">
            <div className="text-fpcred font-bold text-center">I membri della famiglia:</div>
            <div className="border-2 rounded-lg shadow-md">
              {familyMember.map((member) => (
                <div key={member.id} className="flex p-2 justify-center">
                  {member.first_name} {member.last_name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className=" flex flex-col justify-center h-full gap-y-4 sm:items-center">
          <div className="">
            {activeComponent === 'ProfileGrid' && <ProfileGrid goTo={handleShowComponent} />}
            {activeComponent === 'EditProfile' && <EditProfile goTo={handleShowComponent} />}
            {activeComponent === 'ChangePassword' && <ChangePassword goTo={handleShowComponent} />}
            {activeComponent === 'DeleteAccount' && <DeleteAccount goTo={handleShowComponent} />}
            {activeComponent === 'AddFamilyMember' && <AddFamilyMember goTo={handleShowComponent} />}
          </div>
        </div>
      </div>
      <div className="flex border-2 justify-center">
        {auth.isAuth && (
          <div onClick={logout} className="flex flex-row items-center mb-10 justify-center cursor-pointer">
            <img
              src="https://icongr.am/material/logout.svg?size=64&color=FF5757"
              alt="logout-icon"
              className="w-8 h-8"
            />
            <span className="text-red-500 font-bold">Logout</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default withAuth(Profile);
