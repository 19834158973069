import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { AuthProvider } from '../context/AuthContext';
import ActionBar from '../components/ActionBar';
import useScreenWidth from '../hooks/useScreenWidth';
import { useLoading } from '../context/LoadingContext';
import Loading from '../components/Loading';
import { useError } from '../context/ErrorContext';
import ErrorModal from '../components/modal/ErrorModal';

interface MainContentProps {
  Page: any;
}

function MainContent({ Page }: MainContentProps) {
  const location = useLocation();
  const [showDescription, setShowDescription] = useState(true);
  const [showNavbar, setShowNavbar] = useState(true);
  const screenWidth = useScreenWidth();
  const { loading } = useLoading();
  const { error } = useError();

  useEffect(() => {
    setShowDescription(['/login', '/workwithus', '/pricing', '/about', '/'].includes(location.pathname));
    setShowNavbar(!['family', 'teacher'].includes(location.pathname.split('/')[1]));
  }, [location.pathname]);

  const isMobile = screenWidth < 640;
  const isTablet = screenWidth >= 640 && screenWidth < 1024;
  return (
    <>
      <AuthProvider>
        {showNavbar && <Navbar descriptionVisible={showDescription} />}
        <div className={`${!showNavbar && 'flex h-screen w-full'} pb-[68px] z-20`}>
          {!showNavbar && !isMobile && !isTablet && <Sidebar pathname={location.pathname} />}
          {loading && <Loading />}
          {error.error && <ErrorModal/>}
          <div className="p-2 md:p-4 w-full overflow-auto">
            <Page />
          </div>
        </div>
        {!showNavbar && (isMobile || isTablet) && <ActionBar pathname={location.pathname} />}
      </AuthProvider>
    </>
  );
}

export default MainContent;
