import { useTranslation } from 'react-i18next';
import { ReactComponent as Insegnante } from '../ui/Insegnante.svg';
import { ReactComponent as Professionisti } from '../ui/Professionisti.svg';
import { ReactComponent as Libri } from '../ui/Libri.svg';
import { ReactComponent as Servizio1 } from '../ui/Servizio1.svg';
import { ReactComponent as Servizio2 } from '../ui/Servizio2.svg';
import { ReactComponent as Servizio3 } from '../ui/Servizio3.svg';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useAuth();
  function loginRedirect() {
    navigate('/login');
  }

    if(auth.isFamily){
      navigate('/family/home');
      return null;
    } else if(auth.isTeacher){
      navigate('/teacher/home');
      return null;
    }

  return (
    <div className="flex flex-col max-w-5xl gap-y-4 mx-auto bg-fpcpink h-auto">
      <div className="flex flex-col sm:justify-between sm:flex-row">
        {/*sezione testo*/}
        <div className="p-4 flex flex-col  md:justify-center md:items-center ">
          <div className="flex flex-col">
            <h1 className="text-4xl font-bold text-fpcred pb-4 md:text-5xl">
              Ripetizioni
              <span className="text-black">
                {' '}
                per tutti i livelli
                <br />
                <span className="text-black"> di istruzione</span>
              </span>
            </h1>

            <h2 className="text-xl text-black">
              Insegnanti selezionati per garantirti la massima qualità <br /> disponibili in pochissimi click!
            </h2>
          </div>

          <div className="flex justify-center items-center p-4 ">
            <button className="p-2 rounded-xl bg-fpcred" onClick={loginRedirect}>
              <h2 className="text-white">Prenota la tua lezione</h2>
            </button>
          </div>
        </div>

        {/*sezione immagine*/}
        <div className="flex lg:max-w-[30%] flex-col pb-4 sm:justify-center md:my-4">
          <div className="p-4 mb-2 bg-coral rounded-xl md:p-8">
            <h2 className="text-xl font-bold  text-white md:text-3xl">
              Affronta il successo scolastico con noi! Prenota ora le tue lezioni private
            </h2>
          </div>

          <div className="flex gap-x-4">
            <div className="flex  justify-center bg-coral rounded-xl w-2/3 ">
              <Insegnante className="w-full h-full" />
            </div>
            <div className="flex justify-center bg-coral rounded-xl w-1/3">
              <Libri className="w-full h-full" />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <div className="py-2 px-4 max-w-sm rounded-xl bg-red-300">
          <h3 className="text-fpcred text-center">Perchè scegliere Formando Percorsi?</h3>
        </div>
      </div>
      {/*sezione beneficio 1*/}
      <div className="flex flex-col md:flex-row p-4 bg-white rounded-xl shadow-lg">
        <div className="flex justify-center items-center">
          <Professionisti className="w-12/12" />
        </div>
        <div className="flex flex-col items-center md:justify-center">
          <h1 className="text-3xl font-bold text-black">
            Affidati ai<span className="text-fpcred"> Professionisti</span>
          </h1>
         
          <h2 className="text-center">
            Ogni insegnante è selezionato con attenzione per garantire alle famiglie un servizio di qualità.
            <br />
            <br />
            Ogni studente viene affiancato uno ad uno da un insegnante specializzato, che svolge il lavoro con
            continuità.
          </h2>
        </div>
      </div>

      {/*sezione beneficio 2*/}
      <div className="flex flex-col gap-y-4 ">
        <div className="flex flex-col items-center">
          <h2 className="text-3xl font-bold text-fpcred">Un servizio completo</h2>
        </div>

        <div className="flex p-2 sm:p-4 justify-around bg-white rounded-xl ">
          <div className="w-1/4">
            <Servizio1 className="w-full h-full" />
          </div>

          <div className="w-2/5 flex justify-center items-center">
            <h3 className="md:text-xl">Una piattaforma veloce e intuitiva per prenotare la tua lezione in pochi click!</h3>
          </div>
        </div>

        <div className="flex p-2 sm:p-4 justify-around bg-white rounded-xl ">
          <div className="w-2/5 flex justify-center items-center">
            <h3 className="md:text-xl">Lezioni di qualsiasi materia e livello!</h3>
          </div>

          <div className="w-1/4">
            <Servizio2 className="w-full h-full" />
          </div>
        </div>

        <div className="flex p-2 sm:p-4 justify-around bg-white rounded-xl ">
          <div className="w-1/4">
            <Servizio3 className="w-full h-full" />
          </div>

          <div className="w-2/5 flex justify-center items-center">
            <h3 className="md:text-xl">Il nostro team ti permetterà di trovare una soluzione a qualsiasi tua esigenza!</h3>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
