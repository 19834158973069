import React, { useState, useEffect } from 'react';
import { ProfileComponent } from '../../pages/family/Profile';
import { FamilyInfo, Province } from '../../model/FamilyInfo';
import api from '../../utils/api';
import { useLoading } from '../../context/LoadingContext';
import { useError } from '../../context/ErrorContext';
import { City } from '../../model/FamilyInfo';

interface EditProfileProps {
  goTo: (component: ProfileComponent) => void;
}

const EditProfile: React.FC<EditProfileProps> = ({ goTo }) => {
  const [familyInfo, setFamilyInfo] = useState<FamilyInfo>();
  const [cities, setCities] = useState<City[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const { setLoading } = useLoading();
  const { setError } = useError();

  function onSave(component: ProfileComponent) {
    setLoading(true);
    api
      .patch('/family/' + familyInfo.id, familyInfo)
      .then((response) => {
        console.log(response.data);
        goTo(component);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    api
      .get('/family')
      .then((response) => {
        console.log(response.data.user);
        setFamilyInfo(response.data.user);
      })
      .catch((error) => {
        console.error(error);
        setError(true, error.response.data.message, error.response.status);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setFamilyInfo((prev) => ({ ...prev, [name]: value }));
  }

  if (!familyInfo) {
    return null; // Ritorna null se familyInfo non è ancora stato caricato
  }

  return (
    <div className="flex flex-col p-4 bg-white rounded-lg shadow-md">
      <div className="font-bold text-center">
        <h2>Informazioni personali</h2>
      </div>
      <div className="flex flex-col gap-2 py-4">
        <div className="flex flex-col">
          <div className="text-fpcred font-bold">Nome</div>
          <input
            className="border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
            name="first_name"
            value={familyInfo.first_name}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-fpcred font-bold">Cognome</div>
          <input
            className="border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
            name="email"
            value={familyInfo.email}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-fpcred font-bold">Telefono</div>
          <input
            className="border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
            name="phone"
            value={familyInfo.phone}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-fpcred font-bold">Codice fiscale</div>
          <input
            className="border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
            name="cf"
            value={familyInfo.cf}
            type="text"
            onChange={handleChange}
          />
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col w-3/5">
            <div className="text-fpcred font-bold">Città</div>
            <input
              className="border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
              name="city"
              value={familyInfo.city.name}
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col w-2/5">
            <div className="text-fpcred font-bold">Cap</div>
            <input
              className=" border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
              name="zip"
              value={familyInfo.zip}
              type="text"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <div className="flex flex-col w-3/4">
            <div className="text-fpcred font-bold">Indirizzo</div>
            <input
              className=" border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
              name="street_name"
              value={familyInfo.street_name}
              type="text"
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col w-1/4">
            <div className="text-fpcred font-bold">N° Civico</div>
            <input
              className="border-2 border-black border-opacity-50 rounded-lg h-8 p-4"
              name="street_number"
              value={familyInfo.street_number}
              type="text"
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center gap-4">
        <button className="bg-fpcred text-white rounded-lg p-2 w-1/2" onClick={() => goTo('ProfileGrid')}>
          Indietro
        </button>
        <button className="bg-fpcred text-white rounded-lg p-2 w-1/2" onClick={() => onSave('ProfileGrid')}>
          Salva
        </button>
      </div>
    </div>
  );
};

export default EditProfile;
