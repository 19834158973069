import React from 'react';
import { ProfileComponent } from '../../pages/family/Profile';

interface ProfileGridProps {
  goTo: (component: ProfileComponent) => void;
}

const ProfileGrid: React.FC<ProfileGridProps> = ({ goTo }) => {
  return (
    <div className="h-96 w-full sm:w-96 sm:h-96 grid grid-cols-2 gap-4">
      <div
        className="w-full rounded-xl bg-white flex justify-center items-end p-4 text-sm text-center font-bold shadow-md cursor-pointer"
        onClick={() => goTo('EditProfile')}>
        <p>Informazioni personali</p>
      </div>
      <div
        className="w-full rounded-xl bg-white flex justify-center items-end p-4 text-sm text-center font-bold shadow-md cursor-not-allowed opacity-50">
        {/* onClick={() => goTo('AddFamilyMember')} */}
        <p>Aggiungi membro</p>
      </div>
      <div
        className="w-full rounded-xl bg-white flex justify-center items-end p-4 text-sm text-center font-bold shadow-md cursor-not-allowed opacity-50">
        {/* onClick={() => goTo('ChangePassword')} */}
        <p>Modifica password</p>
      </div>
      <div
        className="w-full rounded-xl bg-white flex justify-center items-end p-4 text-sm text-center font-bold shadow-md cursor-not-allowed opacity-50">
         {/* onClick={() => goTo('DeleteAccount')} */}
        <p>Cancella account</p>
      </div>
    </div>
  );
};

export default ProfileGrid;
